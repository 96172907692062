import { useState } from "react";
import Dropdown from "../Inputs/Dropdown";
import { BudgetItem, DateTime, SelectableItem, TimeframeItem } from "../../types";
import { Grid } from "@mui/material";
import { ExclamationCircle } from "react-bootstrap-icons";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditBudgetTimeframe = (props: any) => {
	let [budget, setBudget] = useState(props.Item as BudgetItem);
	let timeframes: TimeframeItem[] = props.Timeframes;

	return (<>
		<ModalHeader Title="Change Timeframe" Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container>
				<Grid item xs={12} className="mx-2 mb-3">
					When does the budget reset?
				</Grid>
				<Dropdown Size={12}
					Class="mb-0"
					Label="Timeframe"
					Options={timeframes
						.map(t => {
							return {
								Id: t.timeframeId,
								Value: TimeframeDescription(t)
							} as SelectableItem;
						})}
					Index={0}
					Value={budget.variant.timeframe?.timeframeId ?? budget.timeframeId}
					OnChange={UpdateTimeframe} />
				<Grid item className="small" xs={2}>
				</Grid>
				<Grid item className="small" xs={8}>
					<ExclamationCircle className="mx-2" />{`The new Timeframe will begin on ${budget.variant.startDate} and will continue for all budget periods moving forward until changed again. All previous budget periods will not be affected by this change.`}
				</Grid>
				<Grid item className="small" xs={2}>
				</Grid>
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(budget)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		if (!budget.description) return false;
		return true;
	}

	function UpdateTimeframe(newValue: any) {
		let item = ({ ...budget, timeframeId: newValue });
		setBudget(item);
	}

	function TimeframeDescription(timeframe: TimeframeItem) {
		let desc = timeframe.description;
		let additional = "";
		let weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		if (timeframe.days > 0) {
			if (timeframe.days % 7 === 0) {
				additional = ` on ${weekdays[timeframe.startDate.Value.getDay()]}`;
			}
			return `${desc} - Every ${timeframe.days} Days${additional}`;
		} else if (timeframe.months > 1) {
			return `${desc} - Every ${timeframe.months} Months on the ${GetDateString(timeframe.startDate.Value.getDate())}`;
		} else if (timeframe.months > 0) {
			return `${desc} - Every Month on the ${GetDateString(timeframe.startDate.Value.getDate())}`;
		} else {
			let first = timeframe.endDate1.Value.getDate();
			let second = timeframe.endDate2.Value.getDate();
			let firstString = GetDateString(first);
			let secondString = GetDateString(second);
			if (second > 28) {
				secondString = "the Last Day";
			}
			return `${desc} - On the ${firstString} and ${secondString} of every month`;
		}
	}

	function GetDateString(date: number) {
		switch (date) {
			case 1:
			case 21:
			case 31:
				return `${date}st`;
			case 2:
			case 22:
				return `${date}nd`;
			case 3:
			case 23:
				return `${date}rd`;
			default:
				return `${date}th`;
		}
	}
}

export default EditBudgetTimeframe;