/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Description from "../Inputs/Description";
import Dropdown from "../Inputs/Dropdown";
import Amount from "../Inputs/Amount";
import Date from "../Inputs/Date";
import { BudgetItem, CurrentUser, SelectableItem, UserItem, BudgetCategoryAmount, TransactionItem, USCurrency, ReceiptEmailItem } from "../../types";
import { useSelector } from "react-redux";
import { Backdrop, Button, CircularProgress, Grid, Skeleton, styled } from "@mui/material";
import { BoxArrowInUp, CloudUploadFill, ExclamationCircleFill, ReceiptCutoff } from "react-bootstrap-icons";
import FileResizer from "react-image-file-resizer";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { useFetch } from "../../features/Fetch/Fetch";
import Toggle from "../Inputs/Toggle";
import ModalHeader from "./ModalHeader";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import Divider from "../Inputs/Divider";
import AmountWithTooltip from "../Inputs/AmountWithTooltip";
import { Deferred } from "q";
import Q from "q";
import AutoCompleteMerchant from "../Inputs/AutoCompleteMerchant";
import { useItems } from "../../features/Fetch/Items";
import DropdownMenu from "../Inputs/DropdownMenu";
import ImportQuickReceipt from "./ImportQuickReceipt";

const EditCheckRequest = (props: any) => {
	let repo = useItems();
	let allTransactions = repo.SortedCheckRequests() ?? [] as TransactionItem[];
	let receiptEmails: ReceiptEmailItem[] = useSelector((state: any) => state.receiptEmails.value);

	let [checkRequest, setCheckRequest] = useState(props.CheckRequest as TransactionItem);
	let [fetching, setFetching] = useState(true);
	let [overlay, setOverlay] = useState(false);
	let [receiptOpen, setReceiptOpen] = useState(false);
	let [openQuickReceipt, setOpenQuickReceipt] = useState(false);

	let budgets: BudgetItem[] = props.Budgets;
	budgets = budgets.filter(b => (b.userIds?.length ?? 0) > 0 && (b.categories?.length ?? 0) > 0 && b.categories.filter(c => c.categoryId > 0).length > 0);
	let myBudgets = budgets.filter(b => b.isMine);
	let users: UserItem[] = props.Users;
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let request = useFetch();

	useEffect(() => {
		if (checkRequest.checkRequest!.accountTransactionCheckRequestId > 0) {
			request
				.get(`Transactions/Receipt/${checkRequest.accountTransactionId}`)
				.then((receipt: TransactionItem) => {
					let item = {
						...checkRequest,
						receipts: receipt.receipts,
					};
					setCheckRequest({
						...item
					});
					setFetching(false);
				});
		} else {
			setFetching(false);
		}
	}, []);

	let PDFJS = require('pdfjs-dist/webpack');

	const VisuallyHiddenInput = styled('input')({
		clip: 'rect(0 0 0 0)',
		clipPath: 'inset(50%)',
		height: 1,
		overflow: 'hidden',
		position: 'absolute',
		bottom: 0,
		left: 0,
		whiteSpace: 'nowrap',
		width: 1
	});

	if (budgets.length === 0 || users.length === 0) {
		return <>Budget approval process is not setup. You cannot use this feature at this point.</>
	}

	let bca: BudgetCategoryAmount[] = [];
	checkRequest.budgetCategoryAmounts.forEach(bc => {
		if (budgets.filter(b => b.budgetId === bc.budgetId).length === 0) {
			bca.push({ ...bc, budgetId: budgets[0].budgetId });
		} else if (bc.categoryId < 1 || checkRequest.checkRequest!.reviewerId < 1) {
			var catId = bc.categoryId;
			var revId = checkRequest.checkRequest!.reviewerId;
			var budg = budgets.find(b => bc.budgetId === b.budgetId)!;
			if (catId < 1) {
				catId = budg.categories[0].categoryId;
			}
			if (revId < 1) {
				let userId = budg.userIds.find(b => users.map(u => u.userAccountId).includes(b));
				if (userId) {
					revId = userId;
				} else {
					revId = users[0].userAccountId;
				}
			}
			bca.push({ ...bc, categoryId: catId, reviewerId: revId });
		}
	})

	let showQuickReceipt = (receiptEmails?.length ?? 0) > 0;

	let ttColor = 'info';
	let total = checkRequest.checkRequest!.amount;
	let actualTotal: number | null = ActualTotal(checkRequest);
	if (actualTotal === 0) actualTotal = null;

	return (<>
		<ModalHeader Title={`${checkRequest.checkRequest!.accountTransactionCheckRequestId > 0 ? 'Edit' : 'Create'} Check Request`} CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(checkRequest)} />
		<div className="modal-body mt-5">
			<Grid container columnSpacing={1} className="mt-4">
				{user.isHoH && checkRequest.checkRequest!.accountTransactionCheckRequestId !== 0 && checkRequest.created && (
					<Grid item xs={12} className="mb-1">Entered by {checkRequest.created}</Grid>
				)}
				{(fetching) ?
					<>
						Retrieving Invoice...
						<Skeleton variant='rounded' width='100%' height={600} />
					</> : 
					<></>}
				{checkRequest.receipts?.map((receipt: any) => {
					var rowNum = checkRequest.receipts.indexOf(receipt);
					return (
						<div className="col-12 centered">
							<InnerImageZoom src={`data:image/bmp;base64,${receipt}`} className="img-zoom mb-3" zoomType="hover" height={600} hideHint={true} zoomScale={1.5} />
							<Button
								className="mb-0"
								component="label"
								variant="text"
								onClick={() => RemoveReceipt(rowNum)}>
								Remove
							</Button>
							<Divider />
						</div>
					);
				})}
				<Amount Size={6} Value={checkRequest.checkRequest!.amount} OnChange={(val: any) => UpdateTotalAmount(val.target.value)} />
				<Grid item xs={6}>
					{!showQuickReceipt ? (
						<Button
							className="mb-3 right"
							component="label"
							variant="contained"
							startIcon={<CloudUploadFill />}>
							Add Invoice
							<VisuallyHiddenInput type="file" onChange={UploadFile} />
						</Button>
					) :
						(
							<DropdownMenu OpenElement={
								<Button
									className="mb-3"
									component="label"
									variant="contained"
									startIcon={<BoxArrowInUp />}>
									Import
								</Button>
							}
								Items={AddMenuItems() ?? []}
								Open={receiptOpen}
								OnOpen={() => setReceiptOpen(true)}
								OnClose={() => setReceiptOpen(false)}>
							</DropdownMenu>
						)}
				</Grid>
				<AutoCompleteMerchant Transactions={allTransactions} Label="Pay to the order of..." Value={checkRequest.merchant} OnChange={UpdateMerchant} Size={12} />
					{checkRequest.checkRequest!.approvalState === -1 && (
						<Grid item xs={12} className="mb-3 bg-warning" >
							<ExclamationCircleFill className="text-danger" />
							{(checkRequest.checkRequest!.approvalNote?.length ?? 0) > 0 && (
								<>
								From {users.find(u => u.userAccountId === checkRequest.checkRequest!.reviewerId)?.firstName} {users.find(u => u.userAccountId === checkRequest.checkRequest!.reviewerId)?.lastName}: {checkRequest.checkRequest!.approvalNote}
								</>
							)}
						</Grid>
				)}
				{checkRequest.budgetCategoryAmounts.map(budget => {
					var rowNum = checkRequest.budgetCategoryAmounts.indexOf(budget);
					return (<>
						<Divider />
						<Grid container>
							{budgets.filter(b => b.budgetId === budget.budgetId).length > 0 ? (
								<>
									<Dropdown Size={6}
										Class="mb-0"
										Label="Budget"
										Options={GetBudgetDict()}
										Index={rowNum}
										Value={budget.budgetId}
										OnChange={UpdateBudget} />
									<Dropdown Size={6}
										Class="mb-0"
										Label="Category"
										Options={GetCategoryDict(budget)}
										Index={rowNum}
										Value={budget.categoryId}
										OnChange={UpdateCategory} />
									{checkRequest.budgetCategoryAmounts.length > 1 ? (
										<AmountWithTooltip
											Size={6}
											Class="mb-0"
											Tooltip={DisplayBudgetedAmounts(true)}
											TooltipClass={`tooltip-${ttColor}`}
											ArrowClass={`arrow-${ttColor}`}
											Value={budget.amount}
											OnChange={(val: any) => UpdateAmount(val.target.value, rowNum)} />
									) : (<Grid item xs={5} />)}
									<Description Size={12} Label="Additional Details" Value={budget.details} OnChange={(val: any) => UpdateDetails(val.target.value, rowNum)} />
								</>
							) :
								(
									<>
										Part of this check request is assigned to a budget you no longer have access to.
										<Button className="mb-3" component="label" variant="contained" onClick={() => UpdateBudget(rowNum, 0)}>
											Change Budget
										</Button>
									</>
								)}
						</Grid>
					</>);
				})}
				<Grid container>
					<Grid item xs={6}>
						<Button onClick={AddBudget}>
							Split{checkRequest.budgetCategoryAmounts.length > 1 ? " Again" : ""}
						</Button>
					</Grid>
					{checkRequest.budgetCategoryAmounts.length > 1 && (
						<Grid item xs={6}>
							<Button onClick={() => RemoveBudget(checkRequest.budgetCategoryAmounts.length - 1)}>
								Remove
							</Button>
						</Grid>
					)}
				</Grid>
				<Divider />
				{checkRequest.budgetCategoryAmounts.length > 1 && DisplayBudgetedAmounts()}
				<Dropdown Size={6}
					Class="mb-0"
					Label="Select an approver"
					Options={users
						.filter(u => u.isActive)
						?.map(u => {
							return {
								Id: u.userAccountId,
								Value: `${u.firstName} ${u.lastName}`
							} as SelectableItem;
						})}
					Value={checkRequest.checkRequest!.reviewerId}
					OnChange={UpdateApprover} />
				<Date Size={12} Label="Date Needed" Value={checkRequest.checkRequest!.dateNeeded} OnChange={UpdateDate} />
				<Toggle Size={12} OnChange={UpdateNeedsMailed} Checked={checkRequest.checkRequest!.needsMailed} Label="Check needs to be mailed" />
				{checkRequest.checkRequest!.needsMailed && <>
					<Description Size={12} Label="Address" Value={checkRequest.checkRequest!.mailingAddress} OnChange={(val: any) => UpdateAddress(val.target.value)} />
					<Description Size={7} Label="City" Value={checkRequest.checkRequest!.city} OnChange={(val: any) => UpdateCity(val.target.value)} />
					<Description Size={2} Label="State" Value={checkRequest.checkRequest!.stateName} OnChange={(val: any) => UpdateState(val.target.value)} />
					<Description Size={3} Label="Zipcode" Value={checkRequest.checkRequest!.zipCode} OnChange={(val: any) => UpdateZip(val.target.value)} />
				</>}
				{showQuickReceipt &&
					<ImportQuickReceipt Open={openQuickReceipt} Close={CloseQuickReceipt} Items={receiptEmails} Confirm={ConfirmQuickReceipt} />
				}
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={Save} Close={props.Close} />
		<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
			<CircularProgress />
		</Backdrop>
	</>);

	function GetBudgetDict() {
		let result: SelectableItem[] = [];
		result.push({
			Id: -2,
			Value: "Your Budgets"
		});
		myBudgets.forEach(b => result.push({
			Id: b.budgetId,
			Value: b.description
		}));
		result.push({
			Id: -2,
			Value: "Others"
		});
		budgets.filter(b => !b.isMine && b.budgetId > 0).forEach(b => result.push({
			Id: b.budgetId,
			Value: b.description
		}));
		return result;
	}
	function GetCategoryDict(budget: BudgetCategoryAmount) {
		let result: SelectableItem[] = [];
		budgets
			.find(b => b.budgetId === budget.budgetId)
			?.categories
			?.forEach(b => result.push({
				Id: b.categoryId,
				Value: b.description
		}));
		return result;
	}
	function AddMenuItems() {
		let result: any[] = [];

		result.push({
			Text: <Button className="mb-3" component="label" variant="text" startIcon={<CloudUploadFill />}>
				Upload Image
				<VisuallyHiddenInput type="file" onChange={UploadFile} />
			</Button>,
			OnClick: () => { }
		});

		if (showQuickReceipt) {
			result.push({
				Icon: <ReceiptCutoff />,
				Text: 'Import Quick Receipt',
				OnClick: QuickReceipt
			});
		}

		return result;
	}
	function ActualTotal(item: TransactionItem) {
		return Math.round(item.budgetCategoryAmounts.reduce((total, t) => total + (t.amount * 100), 0)) / 100;
	}
	function ShouldShowSave() {
		if (checkRequest.budgetCategoryAmounts.length > 1 && total !== ActualTotal(checkRequest)) return false;
		if (!checkRequest.merchant || !checkRequest.checkRequest!.dateNeeded || checkRequest.budgetCategoryAmounts.filter(b => b.budgetId < 1).length > 0 || checkRequest.budgetCategoryAmounts.filter(b => b.categoryId < 1).length > 0 || checkRequest.checkRequest!.reviewerId < 1) return false;
		return true;
	}
	function UpdateMerchant(event: any, newValue: string) {
		var item = ({ ...checkRequest, merchant: newValue });
		setCheckRequest(item);
	}
	function AddBudget() {
		if (checkRequest.budgetCategoryAmounts.length === 1 && checkRequest.budgetCategoryAmounts[0].amount === total) {
			let bcas = [] as BudgetCategoryAmount[];
			bcas.push({ ...checkRequest!.budgetCategoryAmounts[0], amount: 0 });
			checkRequest = { ...checkRequest, budgetCategoryAmounts: bcas };
		}

		let bca: BudgetCategoryAmount[] = [...(checkRequest.budgetCategoryAmounts)];
		bca.push({
			budgetId: budgets[0].budgetId,
			categoryId: budgets[0].categoryIds[0],
			amount: 0
		} as BudgetCategoryAmount)
		let item = ({ ...checkRequest, budgetCategoryAmounts: bca });
		setCheckRequest(item);
	}
	function UpdateBudget(newValue: any, index: number) {
		if (!index) index = 0;
		let bca: BudgetCategoryAmount[] = [];
		checkRequest.budgetCategoryAmounts.forEach(budget => {
			if (checkRequest.budgetCategoryAmounts.indexOf(budget) === index) {
				let reviewerId: number | null = null;
				if (myBudgets.filter(b => b.budgetId === newValue).length === 0) {
					reviewerId = budget.reviewerId;
					let budgetUsers = users
						.filter(u => u.isActive && budgets.find(b => b.budgetId === newValue)?.userIds.includes(u.userAccountId));
					if (budgetUsers.length > 0 && budgetUsers.filter(b => b.userAccountId === (reviewerId ?? 0)).length === 0) {
						reviewerId = budgetUsers[0].userAccountId;
					}
				}
				bca.push({ ...budget, budgetId: newValue, categoryId: budgets.find(b => b.budgetId === newValue)?.categories[0]?.categoryId ?? 0, reviewerId: reviewerId });
			} else {
				bca.push({ ...budget });
			}
		})
		let item = ({ ...checkRequest, budgetCategoryAmounts: bca });
		setCheckRequest(item);
	}
	function RemoveBudget(idx: number) {
		let bca: BudgetCategoryAmount[] = [...(checkRequest.budgetCategoryAmounts)];
		bca.splice(idx, 1);;
		let item = ({ ...checkRequest, budgetCategoryAmounts: bca });
		setCheckRequest(item);
	}
	function UpdateCategory(newValue: any, index: number) {
		if (!index) index = 0;
		let bca: BudgetCategoryAmount[] = [];
		checkRequest.budgetCategoryAmounts.forEach(budget => {
			if (checkRequest.budgetCategoryAmounts.indexOf(budget) === index) {
				bca.push({ ...budget, categoryId: newValue });
			} else {
				bca.push({ ...budget });
			}
		})
		let item = ({ ...checkRequest, budgetCategoryAmounts: bca });
		setCheckRequest(item);
	}
	function UpdateApprover(newValue: any) {
		let item = ({ ...checkRequest, checkRequest: { ...checkRequest!.checkRequest!, reviewerId: newValue === 0 ? null : newValue } });
		setCheckRequest(item);
	}
	function UpdateDate(newValue: any) {
		let item = ({ ...checkRequest, transactionDate: newValue });
		setCheckRequest(item);
	}
	function UpdateTotalAmount(newValue: string) {
		let val = 0;
		if (newValue) {
			val = parseFloat(newValue);
		}
		let item = ({ ...checkRequest, checkRequest: { ...checkRequest!.checkRequest!, amount: val } });
		setCheckRequest(item);
	}
	function UpdateAmount(newValue: string, index: number) {
		let val = 0;
		if (newValue) {
			val = parseFloat(newValue);
			if (isNaN(val)) {
				setCheckRequest({ ...checkRequest });
				return;
			}
		}
		if (index > -1) {
			let amounts = [] as BudgetCategoryAmount[];
			for (let i in checkRequest.budgetCategoryAmounts) {
				let amount = checkRequest.budgetCategoryAmounts[i].amount;
				if (parseInt(i) === index) {
					amount = val;
				}
				amounts.push({ ...checkRequest!.budgetCategoryAmounts[i], amount: amount });
			}
			let item = { ...checkRequest, budgetCategoryAmounts: amounts };
			setCheckRequest(item);
		}
	}
	function UpdateDetails(newValue: string, index: number) {
		if (!index) index = 0;
		let bca: BudgetCategoryAmount[] = [];
		checkRequest.budgetCategoryAmounts.forEach(budget => {
			if (checkRequest.budgetCategoryAmounts.indexOf(budget) === index) {
				bca.push({ ...budget, details: newValue });
			} else {
				bca.push({ ...budget });
			}
		})
		let item = ({ ...checkRequest, budgetCategoryAmounts: bca });
		setCheckRequest(item);
	}
	function UpdateAddress(newValue: string) {
		let item = ({ ...checkRequest, checkRequest: { ...checkRequest!.checkRequest!, mailingAddress: newValue } });
		setCheckRequest(item);
	}
	function UpdateCity(newValue: string) {
		let item = ({ ...checkRequest, checkRequest: { ...checkRequest!.checkRequest!, city: newValue } });
		setCheckRequest(item);
	}
	function UpdateState(newValue: string) {
		let item = ({ ...checkRequest, checkRequest: { ...checkRequest!.checkRequest!, stateName: newValue } });
		setCheckRequest(item);
	}
	function UpdateZip(newValue: string) {
		let item = ({ ...checkRequest, checkRequest: { ...checkRequest!.checkRequest!, zipCode: newValue } });
		setCheckRequest(item);
	}
	function UpdateNeedsMailed(event: any, newValue: boolean) {
		let item = ({ ...checkRequest, checkRequest: { ...checkRequest!.checkRequest!, needsMailed: newValue } });
		setCheckRequest(item);
	}

	async function UploadFile(event: React.ChangeEvent<HTMLInputElement>) {
		let { files } = event.target;
		let file = (files as FileList)[0];
		if (file.type.startsWith("image")) {
			let resizedFile = await new Promise(resolve =>
				FileResizer.imageFileResizer(file, 1280, 1280, file.type.replace("image/", ""), 100, 0, uri => resolve(uri))
			);
			let item = {
				...checkRequest,
				receipts: [...(checkRequest.receipts ?? []), resizedFile!.toString().substring(resizedFile!.toString().indexOf(',') + 1)],
				hasReceipt: true
			};
			setCheckRequest(item);
		} else {
			let data = await ReadFileData(file);
			let pdfjs = await PDFJS;
			let pdfPromise = await pdfjs.getDocument(data).promise;

			var pdf = await pdfPromise;
			iterate(pdf, 1);
		}
	}
	async function iterate(pdf: any, currentPage: number = 1) {
		var pages: any[] = [];
		if (currentPage <= pdf.numPages) pages = await getPage(pdf, currentPage, pages);

		if (pages.length > 0) {
			let item = {
				...checkRequest,
				receipts: [...(checkRequest.receipts ?? [])].concat(pages.map((final: any) => final!.toString().substring(final.toString().indexOf(',') + 1))),
				editedReceipt: true,
				hasReceipt: true,
				receiptEmailId: undefined
			};
			setCheckRequest(item);
		}
	}
	async function getPage(pdf: any, currentPage: number, pages: any[]): Promise<any[]> {
		var deferred: Deferred<any[]> = Q.defer();

		var canvas = document.createElement('canvas');
		let page = await pdf.getPage(currentPage);
		let viewport = page.getViewport({ scale: 1 });
		let context = canvas.getContext("2d");
		canvas.height = viewport.height;
		canvas.width = viewport.width;
		await page.render({ canvasContext: context, viewport: viewport }).promise;

		await canvas.toBlob(async (result) => {
			var resizedFile: any = await new Promise(resolve =>
				FileResizer.imageFileResizer(result!, 1280, 6400, 'jpg', 100, 0, uri => resolve(uri))
			);
			pages.push(resizedFile);

			if (currentPage < pdf.numPages) {
				currentPage++;
				pages = await getPage(pdf, currentPage, pages);
			}
			deferred.resolve(pages);
		});

		return deferred.promise;
	}
	function ReadFileData(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				resolve(e.target!.result);
			};
			reader.onerror = (err) => {
				reject(err);
			};
			reader.readAsDataURL(file);
		});
	};

	function RemoveReceipt(idx: number) {
		let rct: string[] = [...(checkRequest.receipts)];
		rct.splice(idx, 1);;
		let item = ({ ...checkRequest, receipts: rct, receiptEmailIds: ((checkRequest.receiptEmailIds?.length ?? 0) > rct.length) ? undefined : checkRequest.receiptEmailIds });
		setCheckRequest(item);
	}

	function QuickReceipt() {
		setReceiptOpen(false);
		setOpenQuickReceipt(true);
	}
	function CloseQuickReceipt() {
		setOpenQuickReceipt(false);
	}
	function ConfirmQuickReceipt(selectedReceipt: number) {
		setOpenQuickReceipt(false);
		setCheckRequest({ ...checkRequest, hasReceipt: true });
		request
			.get(`ReceiptEmails/Receipt/${selectedReceipt}`)
			.then((receipt: ReceiptEmailItem) => {
				let item = {
					...checkRequest,
					receipts: [...(checkRequest.receipts ?? [])].concat(receipt.receipts!),
					hasReceipt: true,
					receiptEmailIds: [...(checkRequest.receiptEmailIds ?? []), selectedReceipt]
				};
				setCheckRequest({
					...item
				});
			});
	}

	//Budget Split
	function DisplayBudgetedAmounts(isTooltip: boolean = false) {
		if (actualTotal === total) {
			let text = `All ${USCurrency(total ?? 0)} Budgeted!`;
			if (isTooltip) {
				ttColor = 'success';
				return text;
			}
			return (<>
				<Grid item className="text-success mx-3">
					{text}
				</Grid>
				<Divider />
			</>);
		} else if ((actualTotal ?? 0) > (total ?? 0)) {
			let text = `Budgeted ${USCurrency((actualTotal ?? 0) - (total ?? 0))} over total amount of ${USCurrency(total ?? 0)}`;
			if (isTooltip) {
				ttColor = 'error';
				return text;
			}
			return (<>
				<Grid item className="text-danger mx-3">
					{text}
				</Grid>
				<Divider />
			</>);
		} else {
			let text = `${USCurrency((total ?? 0) - (actualTotal ?? 0))} of ${USCurrency(total ?? 0)} remaining`;
			if (isTooltip) {
				ttColor = 'info';
				return text;
			}
			return (<>
				<Grid item className="text-app mb-3 mx-3" xs={12}>
					{text}
				</Grid>
				<Button className="mb-1 mx-1 right" component="label" variant="contained" startIcon={<ReceiptCutoff />} onClick={SplitEvenly}>
					Split Remainder Evenly
				</Button>
				<Button className="mb-1 mx-1 right" component="label" variant="contained" startIcon={<ReceiptCutoff />} onClick={SplitProportionally}>
					Split Remainder Proportionally
				</Button>
				<Divider />
			</>);
		}
	}
	function SplitEvenly() {
		let dif = (total ?? 0) - (actualTotal ?? 0);
		let amounts = [] as BudgetCategoryAmount[];
		for (let i in checkRequest.budgetCategoryAmounts) {
			let amount = checkRequest.budgetCategoryAmounts[i].amount ?? 0;
			let perc = 1 / checkRequest.budgetCategoryAmounts.length;

			if (parseInt(i) === checkRequest.budgetCategoryAmounts.length - 1) {
				let leftover = (total ?? 0) - amounts.reduce((tot, t) => tot + t.amount, 0)
				amount = leftover;
			} else {
				amount += dif * perc;
			}

			amount = Math.round(amount * 100) / 100;
			amounts.push({ ...checkRequest!.budgetCategoryAmounts[i], amount: amount });
		}
		let item = { ...checkRequest, budgetCategoryAmounts: amounts };
		setCheckRequest(item);
	}
	function SplitProportionally() {
		if (!actualTotal) SplitEvenly();

		let dif = (total ?? 0) - (actualTotal ?? 0);
		let amounts = [] as BudgetCategoryAmount[];
		for (let i in checkRequest.budgetCategoryAmounts) {
			let amount = checkRequest.budgetCategoryAmounts[i].amount ?? 0;
			let perc = amount / actualTotal!;

			if (parseInt(i) === checkRequest.budgetCategoryAmounts.length - 1) {
				let leftover = (total ?? 0) - amounts.reduce((tot, t) => tot + t.amount, 0)
				amount = leftover;
			} else {
				amount += dif * perc;
			}

			amount = Math.round(amount * 100) / 100;
			amounts.push({ ...checkRequest!.budgetCategoryAmounts[i], amount: amount });
		}
		let item = { ...checkRequest, budgetCategoryAmounts: amounts };
		setCheckRequest(item);
	}

	function Save() {
		if (checkRequest.budgetCategoryAmounts.length === 1 && checkRequest.budgetCategoryAmounts[0].amount !== total) {
			let bcas = [] as BudgetCategoryAmount[];
			bcas.push({ ...checkRequest!.budgetCategoryAmounts[0], amount: total ?? 0 });
			checkRequest = { ...checkRequest, budgetCategoryAmounts: bcas };
		}
		props.OnSave(checkRequest);
	}
}


export default EditCheckRequest;