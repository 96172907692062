import { useState } from "react";
import Dropdown from "../Inputs/Dropdown";
import { BudgetItem, CategoryItem, CurrentUser, FamilyFeatureValue, FamilyHasAccess, SelectableItem, UserItem } from "../../types";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";
import AutoCompleteCategories from "../Inputs/AutoCompleteCategories";
import Toggle from "../Inputs/Toggle";
import { Features } from "../../constants";

const EditBudgetCategories = (props: any) => {
	let [budget, setBudget] = useState(props.Item as BudgetItem);
	let categories: CategoryItem[] = props.Categories;
	let users: UserItem[] = useSelector((state: any) => state.users.value);
	let user: CurrentUser = useSelector((state: any) => state.userData.value);

	return (<>
		<ModalHeader Title="Categories & Users" Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container>
				<AutoCompleteCategories Size={12}
					Class="mb-0"
					Options={categories
						.map(c => {
							return {
								Id: c.categoryId,
								Value: c.description
							} as SelectableItem;
						})}
					Index={0}
					Value={budget.categoryIds}
					Multiple
					OnChange={UpdateCategories} />
				<Dropdown Size={12}
					Class="mb-0"
					Label="Assigned Users"
					Options={users
						.map(u => {
							return {
								Id: u.userAccountId,
								Value: `${u.firstName} ${u.lastName}`
							} as SelectableItem;
						})}
					Index={0}
					Value={budget.userIds}
					Multiple
					OnChange={UpdateUsers} />
				<Toggle Size={12} Label="Require approval from unassigned users" OnChange={UpdateRequiresApproval} Checked={budget.requiresApproval} className="my-3" />
				<Toggle Size={12} Label={`Exclude items in this budget from ${FamilyFeatureValue(user, Features.TransactionTitle)} page`} OnChange={UpdateIsViewTransactions} Checked={!budget.isViewTransactions} className="my-3" />
				{FamilyHasAccess(user, Features.Income) &&
					<Toggle Size={12} Label="Use Budget for Income Only" OnChange={UpdateIsIncome} Checked={budget.isIncome} className="my-3" />
				}
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(budget)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		if (!budget.description) return false;
		return true;
	}

	function UpdateCategories(newValue: any) {
		let item = ({ ...budget, categoryIds: newValue });
		setBudget(item);
	}
	function UpdateUsers(newValue: any) {
		let item = ({ ...budget, userIds: newValue });
		setBudget(item);
	}
	function UpdateRequiresApproval(event: any, newValue: boolean) {
		let item = ({ ...budget, requiresApproval: newValue });
		setBudget(item);
	}
	function UpdateIsViewTransactions(event: any, newValue: boolean) {
		let item = ({ ...budget, isViewTransactions: !newValue });
		setBudget(item);
	}
	function UpdateIsIncome(event: any, newValue: boolean) {
		let item = ({ ...budget, isIncome: newValue });
		setBudget(item);
	}
}

export default EditBudgetCategories;