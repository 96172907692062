import { useState } from "react";
import { BudgetItem } from "../../types";
import { Grid } from "@mui/material";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";
import Toggle from "../Inputs/Toggle";

const EditBudgetEnd = (props: any) => {
	let [budget, setBudget] = useState(props.Item as BudgetItem);

	return (<>
		<ModalHeader Title={budget.description} CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(budget)} />
		<div className="modal-body mt-5">
			<Grid container>
				<Toggle Size={12} Label="Cancel/Pause budget after the selected period" OnChange={UpdateEnd} Checked={budget.requiresApproval} className="my-3" />
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(budget)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		if (!budget.description) return false;
		return true;
	}

	function UpdateEnd(event: any, newValue: boolean) {
		let variant = { ...budget.variant, isFinal: newValue }
		let item = ({ ...budget, variant: { ...variant } });
		setBudget(item);
	}
}

export default EditBudgetEnd;